
const MENU_HEADER = [
    {name : "Home", path : "/"},
    {name : "About Us", path : "/about"},
    {name : "Our Products", path : "/products"},
    // {name : "Our Services", path : "/services"},
]

const MENU_FOOTER = [
    {name : "Home", path : "/"},
    {name : "About Us", path : "/about"},
    {name : "Our Products", path : "/products"},
    // {name : "Our Services", path : "/services"},
    //{name : "Our Clients", path : "/services"},
    {name : "Get in Touch", path : "/contact"},
]

export {MENU_HEADER, MENU_FOOTER}