export default function ProductCard({ data, fontSizing, isMobile }) {

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: 0,
      }}
    >
      <div>
        <div
          style={{
            color: '#121212',
            fontFamily: 'Inter',
            fontSize: fontSizing ?? '1.25rem',
            fontWeight: 700,
            padding: 0,
          }}
        >
          {data.title}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          gap: isMobile? '0.75rem': '1.5rem',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            width: isMobile? '100%' : '15rem',
            height: '10rem',
            backgroundImage: `url(${data.img ?? null})`,
            backgroundSize: 'cover',
          }}
        />
        <div
          style={{
            color: '#292929',
            fontFamily: 'Open Sans',
            fontSize: '0.875rem',
            fontWeight: 400,
            flex: '1 0 0',
            display: 'flex',
            gap: isMobile? '1rem' : '1.5rem',
            flexDirection: 'column',
          }}
        >
          <div>
            {data.desc}
          </div>
          <div>
            {data.application}
          </div>
        </div>
      </div>
    </div>
  )
}