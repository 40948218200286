import Palette from "../utils/Palette";
import { MENU_HEADER } from "../utils/Menu";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from 'react'
import { Icon } from "@iconify/react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../assets/logo.png'
export default function Header({ isMobile }) {
    const [showMenu, setShowMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)

    const mobileNav = () => {
        return (
            <>
                <Navbar collapseOnSelect style={{ background: Palette.THEME_BLUE, paddingTop: 30 }} key={expand} expand={false}>
                    <Container fluid>
                        <Navbar.Brand style={{
                            color: "white",
                            fontWeight: 600
                        }} href="/"> <Image style={{ width: 50, marginRight: 10 }} src={logo}></Image><b>BINA STEEL GROUP</b></Navbar.Brand>
                        <Navbar.Toggle onClick={() => {
                            setShowMenu(!showMenu)
                        }} style={{ border: 'none', color: '#fff' }} aria-controls="basic-navbar-nav">
                            <Icon icon={'heroicons-outline:menu-alt-3'} />
                        </Navbar.Toggle>

                        <Offcanvas placement="end" show={showMenu}>
                            <Offcanvas.Header style={{ background: Palette.THEME_BLUE, paddingTop: 35, fontSize: 18, width: '100%' }}>
                                <div style={{ display: 'flex', width: '100%', fontWeight: 600, fontSize: 18, color: 'white', alignItems: 'center', verticalAlign: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                    <Link className="text-decoration-none text-white" to={'/'}>
                                        <Image style={{ width: 50, marginRight: 10 }} src={logo}></Image><b>BINA STEEL GROUP</b>
                                    </Link>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setShowMenu(!showMenu)
                                        }} style={{ color: 'white', fontSize: 20 }} variant="link"><Icon icon={'heroicons-outline:menu-alt-2'} /></Button>
                                    </div>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{ background: Palette.THEME_BLUE }}>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    {
                                        [...MENU_HEADER, { name: 'Get In Touch', path: '/contact' }].map((obj => {
                                            return <Nav.Link style={{ color: location.pathname === obj.path ? 'white' : Palette.THEME_INACTIVE_GREY, }} onClick={() => {
                                                navigate(obj.path)
                                                setShowMenu(!showMenu)
                                            }} href="javascript:;">{obj.name}</Nav.Link>
                                        }))
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>


                    </Container>
                </Navbar>
            </>
        )
    }

    const normalNav = () => {
        return (
            <>
                <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
                    color: "white",
                    background: Palette.THEME_BLUE,
                    height: 'auto',
                    width: "100vw",
                    position: "fixed", top: 0, zIndex: 999
                }}>
                    <Container style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        {/* <Row style={{ height: 100 }}> */}
                        <Col className={"d-flex flex-row align-items-center justify-content-center"} xxl={12} style={{ height: 100 }}>
                            <div>
                                <Link className="text-decoration-none text-white" to={'/'}>
                                    <Image style={{ width: 50, marginRight: 10 }} src={logo}></Image><b>BINA STEEL GROUP</b>
                                </Link>

                            </div>
                            <div style={{
                                flex: 1
                            }}>

                            </div>
                            {!isMobile ?
                                <>
                                    <div className={"d-flex flex-row"}>
                                        {
                                            MENU_HEADER.map((obj => {
                                                return <div
                                                    className={"ms-3 me-3"}
                                                    style={{
                                                        color: location.pathname === obj.path ? 'white' : Palette.THEME_INACTIVE_GREY,
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => navigate(obj.path)}
                                                >
                                                    {obj.name}
                                                </div>
                                            }))
                                        }
                                    </div>
                                    <div className={"ms-3"}>
                                        <Button
                                            style={{
                                                background: Palette.THEME_ACCENT_BLUE,
                                                borderColor: Palette.THEME_ACCENT_BLUE,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => navigate('/contact')}
                                        >
                                            Get In Touch
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <Icon icon={'heroicons-outline:menu-alt-3'} onClick={() => setShowMenu(!showMenu)} />
                                </>
                            }
                        </Col>
                        {/*  </Row> */}

                    </Container>

                </div>
            </>
        )
    }

    const handleRender = () => {
        return isMobile ? mobileNav() : normalNav()
    }

    return (
        handleRender()
    )

}