import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/reusable/ScrollToTop";
import Router from "./components/Router";
import { Helmet } from "react-helmet";
function App() {
    return (
        <>
            <Helmet>
                <title>Bina Steel Group Company Profile</title>
                <meta name="description" content="Bina Steel Group is a prominent steel trading company based in Indonesia. Our company specializes in supplying a wide range of steel related products to various industries across the nation and the region." />
            </Helmet>
            <BrowserRouter>
                <ScrollToTop />
                <Router />
            </BrowserRouter>
        </>

    );
}

export default App;
