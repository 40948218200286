import { useOutletContext } from "react-router-dom";
import Palette from "../utils/Palette";

export default function CustomHeading({text}){
    const [isMobile, setIsMobile] = useOutletContext()

    return <div className={"d-flex flex-column align-items-center justify-content-center"} style={{overflowX: 'clip', textOverflow: 'clip'}}>
        <b style={{color : Palette.THEME_BLUE, fontSize : "1.5em"}}>{text}</b>
        <b className="custom-heading-shadow" style={{color : Palette.SHADOW, fontSize : "2.5em", marginTop : '-1.22em', whiteSpace: 'nowrap', overflowX: 'clip'}}>{text}</b>
        <div style={{height : 2, width : 40, background : Palette.THEME_BLUE,}}/>
    </div>
} 