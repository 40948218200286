import tab_1_product_1 from '../../assets/product/product_image_1.png'
import tab_1_product_2 from '../../assets/product/product_image_2.png'
import tab_1_product_3 from '../../assets/product/product_image_3.png'
import tab_1_product_4 from '../../assets/product/product_image_4.png'
import tab_1_product_5 from '../../assets/product/product_image_5.png'
import tab_1_product_6 from '../../assets/product/product_image_6.png'
import tab_1_product_7 from '../../assets/product/product_image_7.png'
import tab_1_product_8 from '../../assets/product/product_image_8.png'
import tab_1_product_9 from '../../assets/product/product_image_9.png'
import tab_1_product_10 from '../../assets/product/product_image_10.png'
import tab_1_product_11 from '../../assets/product/product_image_11.png'


import tab_2_product_1 from '../../assets/product/product_image_12.png'
import tab_2_product_2 from '../../assets/product/product_image_13.png'
import tab_2_product_3 from '../../assets/product/product_image_14.png'
import tab_2_product_4 from '../../assets/product/product_image_15.png'
import tab_2_product_5 from '../../assets/product/product_image_16.png'
import tab_2_product_6 from '../../assets/product/product_image_17.png'

import tab_3_product_1 from '../../assets/product/product_image_18.png'
import tab_3_product_2 from '../../assets/product/product_image_19.png'
import tab_3_product_3 from '../../assets/product/product_image_20.png'
const Products = {
  "Flat Rolled Products": [
    [
      {
        title: 'Hot Rolled Steel Sheets',
        desc: 'Hot-rolled steel is a steel that had roll-passed at a very high temperature, approximately more than 2000 degrees Fahrenheit which is above the recrystallization temperature for most steel types.',
        application: 'Application: Automotives, Truck frames, Constructions, Household appliance, Steel doors, Shelves, Pipes and tubes and many more.',
        img: tab_1_product_1,
      },
      {
        title: 'Hot Rolled Pickled Oiled',
        desc: 'Hot-rolled steel is a steel that had roll-passed at a very high temperature, approximately more than 2000 degrees Fahrenheit which is above the recrystallization temperature for most steel types.',
        application: 'Application: Automotives, Truck frames, Constructions, Household appliance, Steel doors, Shelves, Pipes and tubes and many more.',
        img: tab_1_product_2,
      },
      {
        title: 'Cold Rolled Steel Sheets',
        desc: 'Cold rolled coil is carbon steel produced by cold rolling method. The surface color is different with hot rolled black and have better surface quality. The sizes are thinner and more accurate.',
        application: 'Application: Cookware, Pipe & tube, General purpose, Automotive and many more.',
        img: tab_1_product_3,
      },
      {
        title: 'Coated Steel Sheets',
        desc: 'Hot-rolled steel is a steel that had roll-passed at a very high temperature, approximately more than 2000 degrees Fahrenheit which is above the recrystallization temperature for most steel types.',
        application: 'Application: Automotives, Truck frames, Constructions, Household appliance, Steel doors, Shelves, Pipes and tubes and many more.',
        img: tab_1_product_4,
      },
    ],
    [
      {
        title: 'Hot Dip Galvanize Steel Sheets',
        desc: 'Hot-dipped galvanized steel is steel that has been coated with a layer of zinc through a process called hot-dip galvanizing.',
        application: 'Application: Electric appliances, Steel office equipment, Construction, Vehicle manufacturing, Furniture and many more.',
        img: tab_1_product_4,
      },
      {
        title: 'Electro Galvanized Steel Sheets',
        desc: 'Electrogalvanized steel is carbon steel with a corrosion-resistant zinc coating applied to one or both sides by electro-deposition. The continuous electrolytic coating process covers the steel surface with an exceptionally uniform thickness, which can enhance forming and joining operations.',
        application: 'Application: Switchboards, Automotive panels, Household appliances, Office equipment and many more.',
        img: tab_1_product_5,
      },
      {
        title: 'Al-Zn Coated Steel Sheets',
        desc: 'Al-Zn Coated Steel Sheets is carbon steel coated with an aluminum-zinc using a continuous hot-dip process.',
        application: 'Application: Industrial roofing and siding, Panel and many more.',
        img: tab_1_product_6,
      },
      {
        title: 'Pre-painted Steel Sheets',
        desc: 'Pre-paint steel is a bare metal substrate or corrosion resistant substrate that has had paint applied to both sides through a continuous coil coating process, which produces a uniform paint finish.',
        application: 'Application: Metal Roof, Garage Doors, Decorative Interior Design & many more.',
        img: tab_1_product_7,
      },
    ],
    [
      {
        title: 'Tin-milled Black Plate',
        desc: 'Tin Mill Black Plate (TMBP) steel is manufactured from hot rolled coils by pickling to remove the surface oxide produced during the hot rolling stage, and cold reducing through a cold mill to the desired thickness.',
        application: 'Application: Cable armor, Cans, Dry battery jackets and many more.',
        img: tab_1_product_8,
      },
      {
        title: 'Tin Plate',
        desc: 'Tinplate is a thin sheet of steel that is coated with a layer of tin on both sides. It is primarily used for packaging purposes due to its excellent corrosion resistance, formability and aesthetic appeal.',
        application: 'Application: Food cans, beverage cans, spray cans, paint cans, batteries & many more.',
        img: tab_1_product_9,
      },
      {
        title: 'Silicon Steel',
        desc: 'Silicon steel, also known as electrical steel or transformer steel, is a special type of steel that contains silicon as the primary alloying element.',
        application: 'Application: Electric Motors and Generators, Magnetic Components and Devices, Electrical Transformers and many more.',
        img: tab_1_product_10,
      },
      {
        title: 'Stainless Steel',
        desc: 'Stainless steel is a versatile metal offering excellent strength and rust protection at an affordable price point. The metal is easily formed and welded to other metals.',
        application: 'Application: Kitchenware, cutlery, medical equipment, architecture, automotive and many more.',
        img: tab_1_product_11,
      },
    ]
  ],
  "Forging Roll": [
    {
      title: 'Work Roll',
      desc: 'These rolls are specially designed and manufactured through the forging process to withstand high temperatures, heavy loads, and severe working conditions.',
      img: tab_3_product_1,
    },
    {
      title: 'Intermediate Roll',
      desc: 'Also known as forged intermediate mill rolls or forged backup rolls, are a type of roll used in rolling mills to support and apply pressure to the workpiece during the metal rolling process.',
      img: tab_3_product_2,
    },
    {
      title: 'Back up Roll',
      desc: 'Forged Back up Roll is an important part in mill plants. It is used to support the work roll and intermediate roll to prevent the quality problems of the plate and strip due to the work roll deformation.',
      img: tab_3_product_3,
    },
  ],
  'Ship & Offshore Heavy Plates': [
    {
      title: 'Marine Research Ship',
      img: tab_2_product_1,
    },
    {
      title: 'Oil Refinery',
      img: tab_2_product_2
    },
    {
      title: 'Offshore rig platform',
      img: tab_2_product_3,
    },
    {
      title: 'Container ship',
      img: tab_2_product_4,
    },
    {
      title: 'Barge',
      img: tab_2_product_5,
    },
    {
      title: 'Tanker ship',
      img: tab_2_product_6,
    },
  ]
}

export default Products;