import { Container, Row, Col, Table } from "react-bootstrap"
import Palette from "../utils/Palette"
import { useState } from "react"
import Products from "../utils/Products"
import ProductCard from "../reusable/ProductCard"
import { useOutletContext } from "react-router-dom"


export default function ProductPage() {
  const menu = ['Flat Rolled Products', 'Ship & Offshore Heavy Plates', 'Forging Roll']
  const [selectedMenu, setSelectedMenu] = useState(menu[0])
  const [isMobile, setIsMobile] = useOutletContext()

  const renderHero = () => {
    return (
      <div
        style={{
          backgroundColor: Palette.THEME_BLUE,
          width: '100%',
          color: 'white',
          padding: '4rem 0rem'
        }}
      >
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <Col
            lg={7}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1.5rem',
            }}
          >
            <Row
              style={{
                textAlign: 'center',
                fontSize: '1.75rem',
                fontWeight: 700,
                alignItems: 'center',
              }}>
              Our Products
            </Row>
            <Row
              style={{
                alignSelf: 'stretch',
                color: '#E0E0E0',
                textAlign: 'center',
                fontFamily: 'Open Sans',
                fontSize: '0.875rem',
                fontWeight: 400,
                padding: '0 15px 0 15px'
              }}
            >
              {/* Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem. */}
            </Row>
          </Col>
        </Container>
      </div >
    )
  }

  const renderMenuList = () => {
    return (
      <div
        style={{
          padding: '2.25rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '0.625rem',
          border: '1px solid #E8E8E8',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Open Sans',
            alignItems: 'flex-start',
            gap: '1rem',
            alignSelf: 'stretch',
          }}
        >
          {menu.map((el, index) => {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  //height: '2rem',
                  padding: '0.5rem 0.75rem',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderLeft: selectedMenu === el ? '2px solid #36528B' : '2px solid rgba(54, 82, 139, 0.20)',
                }}
                onClick={() => setSelectedMenu(menu[index])}
              >
                {el}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderFlatRolled = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '2rem',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            color: '#121212',
            fontFamily: 'Inter',
            fontSize: '1.75rem',
            fontWeight: 700,
            padding: 0,
          }}
        >
          {selectedMenu}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "column",
            gap: isMobile ? '2rem' : '3.75rem',
            marginBottom: isMobile ? '0rem' : '3.75rem',
          }}
        >
          {Products[selectedMenu][0].map(el => {
            return (
              <ProductCard data={el} isMobile={isMobile} />
            )
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "column",
            gap: '1rem',
            marginBottom: isMobile ? '0rem' : '3.75rem',
          }}
        >
          <div
            style={{
              color: '#121212',
              fontFamily: 'Inter',
              fontSize: '1.5rem',
              fontWeight: 700,
              padding: 0,
              gap: isMobile ? '2rem' : '3.75rem',
              marginBottom: isMobile ? '0rem' : '3.75rem',
            }}
          >
            Coated Steel Sheets
          </div>
          {Products[selectedMenu][1].map(el => {
            return (
              <ProductCard data={el} fontSizing={'1rem'} isMobile={isMobile} />
            )
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "column",
            gap: isMobile ? '2rem' : '3.75rem',
          }}
        >
          {Products[selectedMenu][2].map(el => {
            return (
              <ProductCard data={el} isMobile={isMobile} />
            )
          })}
        </div>
      </div>
    )
  }

  const renderForging = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '2rem',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            color: '#121212',
            fontFamily: 'Inter',
            fontSize: '1.75rem',
            fontWeight: 700,
            padding: 0,
          }}
        >
          {selectedMenu}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "column",
            gap: isMobile ? '2rem' : '3.75rem',
            marginBottom: '3.75rem',
          }}
        >
          {Products[selectedMenu].map(el => {
            return (
              <ProductCard data={el} isMobile={isMobile} />
            )
          })}
        </div>
      </div>
    )
  }

  const renderShipOffshore = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '2rem',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            color: '#121212',
            fontFamily: 'Inter',
            fontSize: '1.75rem',
            fontWeight: 700,
            padding: 0,
          }}
        >
          {selectedMenu}
        </div>
        <div
          style={{
            color: '#292929',
            fontFamily: 'Open Sans',
            fontSize: '0.875rem',
            fontWeight: 400,
          }}
        >
          We provide high-strength, durable, and corrosion-resistant steel plate designed to withstand the harsh conditions of marine and offshore environments.
        </div>
        <div
          style={{
            width: '100%'
          }}
        >
          <Table
            borderless
            responsive="lg"
            style={{
              width: '100%',
              textAlign: 'left',
              verticalAlign: 'middle',
            }}
          >
            <thead
              style={{
                verticalAlign: 'middle',
              }}
            >
              <tr>
                <th width="50%">Steel Type</th>
                <th>Steel Grade</th>
                <th>Thickness (mm)</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              <tr>
                <td rowSpan={6}>Ordinary-strength Hull Structural Steel</td>
                <td>A/B/D/E</td>
                <td>≤100</td>
              </tr>
              <tr>
                <td rowSpan={2}>A/B</td>
                <td>≤50</td>
              </tr>
              <tr>
                <td>≤130</td>
              </tr>
              <tr>
                <td rowSpan={2}>D</td>
                <td>≤35</td>
              </tr>
              <tr>
                <td>≤130</td>
              </tr>
              <tr>
                <td>E</td>
                <td>≤130</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div
          style={{
            color: '#292929',
            fontFamily: 'Open Sans',
            fontSize: '0.875rem',
            fontWeight: 400,
          }}
        >
          We also supply Higher-strength Hull Structural Steel, Extra High Strength Steel, High-Toughness Hull Structural Steel, Brittle Crack Arrest Steel, Carbon–manganese steel for low temperature service, Nickel alloy steel for low temperature service, Clad steel plate, Offshore engineering plate and many more.
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "row",
            gap: '1.5rem 1rem',
            marginBottom: '3.75rem',
            flexWrap: "wrap",
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
        >
          {Products[selectedMenu].map(el => {
            return (
              <Col
                style={{
                  display: 'flex',
                  flexDirection: "column",
                  gap: '0.75rem',
                  alignItems: isMobile ? 'flex-start' : 'center',
                }}
              >
                <div
                  style={{
                    height: '12.5rem',
                    width: isMobile ? '9rem' : '20rem',
                    backgroundImage: `url(${el.img})`,
                    backgroundSize: 'cover',
                  }}
                />
                <div
                  style={{
                    color: '#121212',
                    fontFamily: 'Inter',
                    fontSize: '1rem',
                    fontWeight: 600,
                  }}
                >
                  {el.title}
                </div>
              </Col>
            )
          })}
        </div>
      </div >
    )
  }

  const renderTabMenu = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'scroll',
          flexWrap: 'nowrap',
          position: 'sticky',
          top: 100,
          paddingBottom: '0.5rem',
          color: 'white',
          backgroundColor: 'white',
          zIndex: 5,
          //gap: '0.2rem',
        }}
        className="no-scrollbar"
      >
        {
          menu.map((el, index) => {
            //const indexSelected = menu.indexOf(selectedMenu)
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  padding: '0.5rem',
                  //borderRadius: '0.2rem 0.2rem 0 0',
                  overflow: "visible",
                  textOverflow: 'ellipsis',
                  backgroundColor: 'white',
                  color: 'black',
                  //borderTop: '2px solid white',
                  //borderTop: indexSelected === index? '2px solid white' : '2px solid '+Palette.THEME_INACTIVE_GREY,
                  //borderLeft: indexSelected === index? '2px solid white' : '2px solid '+Palette.THEME_INACTIVE_GREY,
                  //borderRight: indexSelected === index? '2px solid white' : '2px solid '+Palette.THEME_INACTIVE_GREY,
                  borderBottom: el === selectedMenu ? '4px solid ' + Palette.THEME_BLUE : null,
                }}
                onClick={() => {
                  setSelectedMenu(menu[index])
                  window.scrollTo(0, 300)
                }}
              >
                {el}
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderLeftSide = () => {
    return (
      <Col
        md={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '1.5rem',
        }}
      >
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'sticky',
          top: 200,
          gap: '1.5rem',
        }}>
          {!isMobile && renderMenuList()}
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifycontent: 'center',
              alignItems: 'flex-start',
              gap: '1rem',
              alignSelf: 'stretch',
            }}
          >
            <div
              style={{
                color: '#121212',
                fontFamily: 'Inter',
                fontSize: '1.25rem',
                fontWeight: '600',
              }}
            >
              Enim qui eos rerum in delectus
            </div>
            <div
              style={{
                color: '#292929',
                fontFamily: 'Open Sans',
                fontSize: '0.875rem',
                fontWeight: 400,
              }}
            >
              Nam voluptatem quasi numquam quas fugiat ex temporibus quo est. Quia aut quam quod facere ut non occaecati ut aut. Nesciunt mollitia illum tempore corrupti sed eum reiciendis. Maxime modi rerum.
            </div>
          </div> */}
        </div>
      </Col>
    )
  }

  const renderRightSide = () => {
    let innerHTML
    switch (selectedMenu) {
      case menu[0]:
        innerHTML = renderFlatRolled();
        break;
      case menu[1]:
        innerHTML = renderShipOffshore();
        break;
      case menu[2]:
        innerHTML = renderForging();
        break;
      default:
        break;
    }

    return (
      <Col md={8}>
        {innerHTML}
      </Col>
    )
  }

  return (
    <div className="d-flex flex-column">
      {renderHero()}
      
      <div
        style={{
          padding: isMobile ? '0rem 1.5rem 1.5rem 1.5rem' : '3.75rem 3rem 7rem 3rem',
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: "wrap",
        }}
      >
        {isMobile && renderTabMenu()}
        {renderLeftSide()}
        {renderRightSide()}
      </div>
    </div >
  )
}