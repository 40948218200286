import { Outlet } from "react-router-dom";
import Palette from "../utils/Palette";
import Header from "../reusable/Header";
import Footer from "../reusable/Footer";
import { useState, useEffect } from "react";

export default function HomeLayout({ }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576)

    useEffect(() => {
        const handleWindowResize = () => {
            setIsMobile(window.innerWidth < 576)
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return <div>
        <Header isMobile={isMobile} />
        <div style={{marginTop: isMobile ? 0 : 100}}>
            <Outlet context={[isMobile, setIsMobile]} />
        </div>
        <Footer isMobile={isMobile} />
    </div>
}