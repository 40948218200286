import {useRoutes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import HomeLayout from "./layout/HomeLayout";
import ProductPage from "./pages/ProductPage";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import ContactPage from "./pages/ContactPage";

export default function Router() {


    let isLoggedIn = !!localStorage.getItem("token")

    let tempRoutes = [
        {
            path: '',
            element: <HomeLayout />,
            children: [
                { path: '', element: <HomePage /> },
                { path: '/products', element: <ProductPage />},
                { path: '/about', element: <AboutPage /> },
                { path: '/services', element: <ServicePage /> },
                { path: '/contact', element: <ContactPage /> },
            ],
        }
    ]

    const routes = useRoutes(tempRoutes);

    return routes;
}
