import Palette from "../utils/Palette";
import { MENU_FOOTER } from "../utils/Menu";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";

export default function Footer({ isMobile }) {
    const navigate = useNavigate()

    return <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
        color: "white",
        background: Palette.THEME_BLUE,
        width: "100vw",
        paddingTop: 50,
        paddingBottom: 50
    }}>
        <Container>
            <Row>
                <Col className={"d-flex flex-row align-items-start justify-content-start " + isMobile ? "mb-1" : ""} md={6}>
                    <div className={"d-flex flex-column"}>
                        <Link className="text-white text-decoration-none" to={'/'}>
                            <b>BinaSteelGroup</b>
                        </Link>
                        {/* <div className={"mt-4 mb-4"}>
                            Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet
                        </div> */}
                        {/* <div className={"d-flex flex-row mb-4"}>
                            <div className={"ms-3 me-3"} style={{fontSize : "1.2em"}}><Icon icon="ic:baseline-facebook" /></div>
                            <div className={"ms-3 me-3"} style={{fontSize : "1.2em"}}><Icon icon="ri:instagram-fill" /></div>
                            <div className={"ms-3 me-3"} style={{fontSize : "1.2em"}}><Icon icon="ri:twitter-fill" /></div>
                            <div className={"ms-3 me-3"} style={{fontSize : "1.2em"}}><Icon icon="mdi:location" /></div>
                        </div> */}
                    </div>
                </Col>
                <Col className={"d-flex flex-column " + isMobile ? "mb-4" : ""} md={2}>
                    <b className={"mb-2"}>Useful Links</b>
                    {
                        MENU_FOOTER.map((obj => {
                            return <div
                                className={"mt-1 mb-1"}
                                style={{
                                    color: 'white' /* Palette.THEME_INACTIVE_GREY */,
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(obj.path)}
                            >
                                {obj.name}
                            </div>
                        }))
                    }
                </Col>
                <Col md={4}>
                    <b>Contact Us</b>
                    <div className={"mt-2"}>
                        Jl. P. Jayakarta 123 Komp Melawan no. 28/3, Jakarta (10730)
                    </div>
                    <div>
                        <div className={"mt-2"}><b>Phone:</b>  021-624-7035</div>
                        <div className={"mt-2"}><b>Email:</b>  admin-marketing01@binasteelpipe.com</div>
                        <div className={"mt-2"}><b>Webiste:</b>  www.binasteelgroup.com</div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}