import { Col, Container, Image, Row } from "react-bootstrap";
import Palette from "../utils/Palette";
import CountUp from "react-countup";

import MockupImage from "../../assets/mockup-image.png"
import MockupImage1 from "../../assets/mockup-image-1.png"
import MockupImage2 from "../../assets/mockup-image-2.png"
import MockupImage3 from "../../assets/mockup-image-3.png"
import MockupServices1 from "../../assets/mockupServices1.png"
import MockupServices2 from "../../assets/mockupServices2.png"
import { Icon } from "@iconify/react";
import CustomHeading from "../reusable/CustomHeading";

import clientAbc from "../../assets/client/abc.png"
import clientAmNs from "../../assets/client/am_ns.png"
import clientDaihatsu from "../../assets/client/daihatsu.png"
import clientGunungGaruda from "../../assets/client/gunung_garuda.png"
import clientHino from "../../assets/client/hino.png"
import clientHonda from "../../assets/client/honda.png"
import clientKrakatauSteel from "../../assets/client/krakatau_steel.png"
import clientMayora from "../../assets/client/mayora.png"
import clientNestle from "../../assets/client/nestle.png"
import clientPln from "../../assets/client/pln.png"
import clientRotaryana from "../../assets/client/rotaryana.png"
import clientSakura from "../../assets/client/sakura.png"
import clientSharp from "../../assets/client/sharp.png"
import clientSpindo from "../../assets/client/spindo.png"
import clientToyota from "../../assets/client/toyota.png"
import clientVesinter from "../../assets/client/vesinter.png"
import clientWuling from "../../assets/client/wuling.png"
import clientYamaha from "../../assets/client/yamaha.png"
import MockupImageDefault from "../../assets/mockupDefault.png"
import { Link, useOutletContext } from "react-router-dom";

export default function HomePage({ }) {
    const [isMobile, setIsMobile] = useOutletContext();
    let clientList = [
        {
            "name": 'Yamaha',
            "image": clientYamaha,
        },
        {
            "name": 'Honda',
            "image": clientHonda,
        },
        {
            "name": 'Toyota',
            "image": clientToyota,
        },
        {
            "name": 'Hino',
            "image": clientHino,
        },
        {
            "name": 'Wuling',
            "image": clientWuling,
        },
        {
            "name": 'Daihatsu',
            "image": clientDaihatsu,
        },
        {
            "name": 'Sharp',
            "image": clientSharp,
        },
        {
            "name": 'Amns',
            "image": clientAmNs,
        },
        {
            "name": 'Sakura',
            "image": clientSakura,
        },
        {
            "name": 'undefined',
            "image": clientSakura
        },
        {
            "name": 'Vesinter',
            "image": clientVesinter,
        },

        {
            "name": 'PLN',
            "image": clientPln,
        },


        {
            "name": 'Nestle',
            "image": clientNestle,
        },

        {
            "name": 'Mayora',
            "image": clientMayora,
        },

        {
            "name": 'Gunung Garuda',
            "image": clientGunungGaruda,
        },
        {
            "name": 'Krakatau Steel',
            "image": clientKrakatauSteel,
        },
        {
            "name": 'undefined',
            "image": clientKrakatauSteel
        },
        {
            "name": 'Abc',
            "image": clientAbc,
        },

        {
            "name": 'Rotaryana',
            "image": clientRotaryana,
        },

        {
            "name": 'Spindo',
            "image": clientSpindo,
        },




    ]

    const renderHeroSection = () => {
        return <>
        
            <div style={{
                background: Palette.THEME_BLUE,
                color: "white", paddingTop: 50, paddingBottom: 50
            }}>
                <Container>
                    <Row>
                        <Col style={{
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        }} md={6}>
                            <b style={{
                                fontSize: "2.5em", lineHeight: "1.3em"
                            }}>
                                General Trading, Stocklist, <br />
                                and Supplies
                            </b>
                            {/* <div style={{ padding: '0 5px 0 5px' }} className={"mt-3 mb-3"}>
                                Facere distinctio molestiae nisi fugit tenetur repellat non praesentium nesciunt optio quis sit odio nemo quisquam. eius quos reiciendis eum vel eum voluptatem eum maiores eaque id optio ullam occaecati odio est possimus vel reprehenderit<br /><br />
                                Facere distinctio molestiae nisi fugit tenetur repellat non praesentium nesciunt optio quis sit odio nemo quisquam. eius quos reiciendis eum vel eum voluptatem eum maiores eaque id optio ullam occaecati odio est possimus vel reprehenderit
                            </div> */}
                            <div>
                                {/* <Row>
                                    <Col className={"d-flex flex-column align-items-center mt-2 mb-3"} xs={3}>
                                        <div style={{ fontWeight: 600, fontSize: "1.5em" }}><CountUp end={232} duration={2} /></div>
                                        <div className={"mt-2 mb-2"} style={{ height: 1, width: 15, background: Palette.THEME_ACCENT_BLUE }}></div>
                                        <div style={{ fontSize: "0.8em" }}>Clients</div>
                                    </Col>
                                    <Col className={"d-flex flex-column align-items-center mt-2 mb-3"} xs={3}>
                                        <div style={{ fontWeight: 600, fontSize: "1.5em" }}><CountUp end={521} duration={2} /></div>
                                        <div className={"mt-2 mb-2"} style={{ height: 1, width: 15, background: Palette.THEME_ACCENT_BLUE }}></div>
                                        <div style={{ fontSize: "0.8em" }}>Projects</div>
                                    </Col>
                                    <Col className={"d-flex flex-column align-items-center mt-2 mb-3"} xs={3}>
                                        <div style={{ fontWeight: 600, fontSize: "1.5em" }}><CountUp end={1453} duration={2} /></div>
                                        <div className={"mt-2 mb-2"} style={{ height: 1, width: 15, background: Palette.THEME_ACCENT_BLUE }}></div>
                                        <div style={{ fontSize: "0.8em" }}>Support</div>
                                    </Col>
                                    <Col className={"d-flex flex-column align-items-center mt-2 mb-3"} xs={3}>
                                        <div style={{ fontWeight: 600, fontSize: "1.5em" }}><CountUp end={32} duration={2} /></div>
                                        <div className={"mt-2 mb-2"} style={{ height: 1, width: 15, background: Palette.THEME_ACCENT_BLUE }}></div>
                                        <div style={{ fontSize: "0.8em" }}>Workers</div>
                                    </Col>
                                </Row> */}
                            </div>
                        </Col>
                        <Col md={6}>
                            <img
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover"
                                }}
                                src={MockupImage1} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    }
    const renderAboutUsSection = () => {
        return <div>
            <Container className={"pt-5 pb-5"}>
                <Row>
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                        <CustomHeading text={"ABOUT US"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col md={6} className={"d-flex flex-column justify-content-center"}>
                        <div style={{ fontSize: "0.8em" }}>
                            Bina Steel Group is a prominent steel trading company based in Indonesia. Our company specializes in supplying a wide range of steel related  products to various industries across the nation and the region.
                            <br /><br />
                            As part of the Bina Steel Group, PT Praja Billionmas Lestari has been Baosteel’s trusted agent since 1997. Over the years, this partnership has allowed us to provide customers with high-quality steel products and exceptional service, solidifying its position as a reliable and trusted steel trading company in Indonesia.
                            <br /><br />
                            Our recent endeavour include establishing subsidiary in Batam at 2022 to expand our reach and cater to the industrial hub there, where the is a significant demand for heavy stell plates and other steel products. Our goal is to support the shipbuilding and other industries in the region.
                            <br /><br />
                            We are a company that is committed to build relationship with clients based in trust and respect. We believe through our experience and variety of products, we are able to deliver and execute our customer’s needs.
                            <br /><br />
                        </div>
                        <div style={{ fontSize: "0.9em", fontWeight: 700 }} className="mb-4">
                            <Link className="text-black text-decoration-none" to={'/about'}>See More  <Icon icon={"mingcute:arrow-right-line"} /></Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            alt={'about-section-image'}
                            src={MockupImage} />
                    </Col>
                </Row>
            </Container>
        </div>
    }

    const renderOurProductsSection = () => {
        return <div>
            <Container style={{ marginTop: isMobile ? 10 : 100 }}>
                <Row>
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                        <CustomHeading text={"OUR PRODUCTS"} />
                    </Col>
                </Row>
                {/* <Row className={"mt-5"}>
                    <Col md={12} className={"d-flex flex-column justify-content-center"}>
                        <div style={{ fontSize: "0.8em" }}>
                            Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet. Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet Lorem ipsum sit dolor amet lorem ipsum sit dolor amet lorem ipsum sit dolor amet
                        </div>
                    </Col>
                </Row> */}
                <Row className={"mt-5 mb-5"}>
                    <Col md={4} style={{ height: isMobile ? 'fit-content' : 'auto', marginBottom: '1rem' }}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={MockupImage} />
                        <b>Flat Rolled Products</b>
                    </Col>
                    <Col md={4} style={{ height: isMobile ? 'fit-content' : 'auto', marginBottom: '1rem' }}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={MockupImage1} />
                        <b>Ship & Offshore Heavy Plates</b>
                    </Col>
                    <Col md={4} style={{ height: isMobile ? 'fit-content' : 'auto', marginBottom: '1rem' }}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={MockupImage3} />
                        <b>Forging Roll</b>
                    </Col>
                    <Col
                        style={{ cursor: "pointer" }}
                        className={"mt-5 d-flex align-items-center justify-content-center"} md={12}>
                        <div style={{ fontSize: "0.9em", fontWeight: 700 }} className={"d-flex align-items-center justify-content-center"}>
                            <Link className="text-black text-decoration-none" to={'/products'}>See More  <Icon icon={"mingcute:arrow-right-line"} /></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }
    const renderServices = () => {
        return (
            <Container style={{ marginTop: isMobile ? 10 : 100 }}>
                <Row>
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                        <CustomHeading text={"OUR SERVICES"} />
                    </Col>
                </Row>
                {/* <Row className={"mt-5"}>
                    <Col md={12} className={"d-flex flex-column justify-content-center align-items-center"}>
                        <div style={{ fontSize: "0.9em" }}>
                            Through our affiliate coil center, we provide these services
                        </div>
                    </Col>
                </Row> */}
                <Row className={"mt-5 mb-5 justify-content-center"}>
                    <Col md={6}>
                        <div className="d-flex justify-content-center">
                            <Image
                                style={{
                                    width: '100%',
                                    maxHeight: '320px',
                                    objectFit: 'cover'
                                }}
                                src={MockupServices1} />

                        </div>
                        <div className="text-center" style={{ marginTop: 10 }}>
                            <b style={{ color: Palette.THEME_BLUE }}>Shearing or Cutting</b>

                        </div>

                    </Col>
                    <Col md={6}>
                        <div className="d-flex justify-content-center">
                            <Image
                                style={{
                                    width: '100%',
                                    maxHeight: '320px',
                                    objectFit: 'cover'
                                }}
                                src={MockupServices2} />
                        </div>

                        <div className={"text-center"} style={{ marginTop: 10 }}>
                            <b style={{ color: Palette.THEME_BLUE }}>Slitting</b>

                        </div>
                    </Col>

                </Row>
                {/* <Row className="mb-3 mt-2">
                    <Col
                        style={{ cursor: "pointer" }}
                        className={"mt-5 d-flex align-items-center justify-content-center"} md={12}>
                        <div className={"d-flex align-items-center justify-content-center"}>
                            <b className={"me-1"}>See More</b>
                            <Icon icon={"mingcute:arrow-right-line"} />
                        </div>
                    </Col>
                </Row> */}

            </Container>
        )
    }

    const renderClient = () => {
        return (
            <Container style={{ marginTop: isMobile ? 10 : 100 }}>
                <Row className="mb-5 mt-5">
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                        <CustomHeading text={"OUR CLIENTS"} />
                    </Col>
                </Row>
                <Row className="mb-5 mt-5">
                    {
                        clientList.map((v) => {
                            return (
                                <Col className="mb-5 d-flex justify-content-center" xs={3}>
                                    <Image
                                        style={{
                                            objectFit: "contain"
                                        }}
                                        alt={v.name} src={v.image} fluid></Image>
                                </Col>
                            )
                        })
                    }

                </Row>
            </Container>
        )
    }

    return <div className={"d-flex flex-column"}>
        {renderHeroSection()}
        {renderAboutUsSection()}
        {renderOurProductsSection()}
        {renderServices()}
        {renderClient()}

    </div>
}