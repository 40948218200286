import { render } from "@testing-library/react"
import { Col, Container, Image, Row } from "react-bootstrap"
import Palette from "../utils/Palette"
import MockupImage from "../../assets/mockup-image.png"
import history5 from "../../assets/mockup_history_img_5.png"
import history2 from "../../assets/mockup_history_img_2.png"
import history3 from "../../assets/mockup_history_img_3.png"
import history4 from "../../assets/mockup_history_img_4.png"
import { Icon } from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"
export default function AboutPage() {
    const ourMissionData = [
        'Delivering high-quality steel products',
        'Develop lasting client relationships by providing exceptional service'
    ]

    const historyData = [
        {
            period: 1992,
            image: history4,
            description: 'We started as a stockist of carbon steel seamless pipes from Baoshan Iron & Steel and Panggang Group Chengdu. We also offer essential pipe fittings and flanges for oil companies'
        },
        {
            period: 1997,
            image: history5,
            description: `Our company is trusted to be the agent of Baoshan Iron and Steel (Baosteel) which is one of the world's largest steel producer. We started selling Baosteel’s Tinplate, wire rod, cold rolled coil, HRPO, Galvanize steel mainly for motorcycle and automotive customers.`
        },
        {
            period: 2017,
            image: history3,
            description: 'We started being a stockist for fast moving products as the demand grow in Indonesia'
        },
        {
            period: 'Present',
            image: history2,
            description: 'We continue to grow our group of companies by being one of indonesia leadings steel trader and expand our product lines to cater a more diverse industries.'
        },

    ]
    const renderHero = () => {
        return (
            <div
                style={{
                    backgroundColor: Palette.THEME_BLUE,
                    width: '100%',
                    color: 'white',
                    padding: '4rem 0rem'
                }}
            >
                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col
                        lg={7}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1.5rem',
                        }}
                    >
                        <Row
                            style={{
                                textAlign: 'center',
                                fontSize: '1.75rem',
                                fontWeight: 700,
                                alignItems: 'center',
                            }}>
                            About Us
                        </Row>
                        <Row
                            style={{
                                alignSelf: 'stretch',
                                color: '#E0E0E0',
                                textAlign: 'center',
                                fontSamily: 'Open Sans',
                                fontSize: '0.875rem',
                                fontWeight: 400,
                                padding: '0 15px 0 15px'
                            }}
                        >
                            {/* Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem. */}
                        </Row>
                    </Col>
                </Container>
            </div >
        )
    }

    return (
        <>
            {renderHero()}
            <Container>
                <Row className={"mt-5 mb-5"}>
                    <Col md={6} className={"d-flex flex-column justify-content-center"}>
                        <div style={{ fontSize: "0.8em" }}>
                            Bina Steel Group is a prominent steel trading company based in Indonesia. Our company specializes in supplying a wide range of steel related  products to various industries across the nation and the region.
                            <br /><br />
                            As part of the Bina Steel Group, PT Praja Billionmas Lestari has been Baosteel’s trusted agent since 1997. Over the years, this partnership has allowed us to provide customers with high-quality steel products and exceptional service, solidifying its position as a reliable and trusted steel trading company in Indonesia.
                            <br /><br />
                            Our recent endeavour include establishing subsidiary in Batam at 2022 to expand our reach and cater to the industrial hub there, where the is a significant demand for heavy stell plates and other steel products. Our goal is to support the shipbuilding and other industries in the region.
                            <br /><br />
                            We are a company that is committed to build relationship with clients based in trust and respect. We believe through our experience and variety of products, we are able to deliver and execute our customer’s needs.
                            <br /><br />
                        </div>
                    </Col>
                    <Col md={6}>
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={MockupImage} />
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-5" md={6} xs={12} sm={12}>
                        <CustomHeading text={"OUR VISION"} />
                        <div className="mt-5 text-center" style={{ fontSize: "0.8em" }}>
                            We strive to be the leader in steel trader in Indonesia and play a significant role in the country's manufacturing growth and success
                        </div>
                    </Col>
                    <Col className="mb-5" md={6}>
                        <CustomHeading text={"OUR MISSION"} />

                        <div className="mt-5" style={{ fontSize: "0.8em" }}>

                            {
                                ourMissionData.map((v) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col className="w-auto" style={{ paddingRight: 0 }} md={1}><Icon icon={"ic:baseline-check"} /></Col>
                                                <Col>{v}</Col>
                                            </Row>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mb-5">
                    <CustomHeading text={"OUR HISTORY"} />
                </Row>

                <Row className="mb-5">
                    {/* Timeline Area*/}
                    <div className="apland-timeline-area">
                        {/* Single Timeline Content*/}
                        {
                            historyData?.map(v => (
                                <>
                                    <div className="single-timeline-area">
                                        <div
                                            className="timeline-date wow fadeInLeft"
                                            data-wow-delay="0.1s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "0.1s",
                                                animationName: "fadeInLeft"
                                            }}
                                        >
                                            <p style={{ fontSize: '1em', fontWeight: 800 }}>{v?.period}</p>
                                        </div>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3">
                                                    <Col md={3}>
                                                        <Image alt={`img-${v?.period}`} src={v?.image} fluid />
                                                    </Col>
                                                    <Col md={9} style={{ fontSize: '0.8em', marginTop: 'auto', marginBottom: 'auto', textAlign: 'left' }}>
                                                        {v?.description}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            ))
                        }

                    </div>

                </Row>


            </Container>
        </>
    )
}